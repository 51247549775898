<template>
  <div>
    <el-dialog :title="`${ echarttsData.title}(${echarttsData.area})`" :visible.sync="dialogVisible" width="1027" :before-close="handleClose">
      <el-row>
        <el-col :span="24" style="margin-top: 16px;">
          <div :id="echartsHistogramId" class="echartsHistogram" style="width: 100%;"> </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title:{
      type:String,
      default:''
    },
  echarttsData:{
    type:Object,
  },

  },
  data() {
    return {
      echartsHistogramId: 'histogram_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      echartsTitle:this.title,
      // dialogVisible:true
    };
  },
watch:{
  title(val){
    if(val){
    this.echartsTitle = val
      this.initEchart()
    }
  },},
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('updateVisible', val)
      }
    }
  },

  created() {

  },
  mounted() {
    this.initEchart()
  },
  updated() {//子组件更新时
    this.initEchart()
  },
  methods: {
    initEchart() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsHistogramId));
      let option;
      option = {
        color: '#0093DB',
        tooltip: {
          trigger: 'axis',
          backgroundColor: "rgba(255, 255, 255,0.8)",
          borderColor: '#EDF0F4',
          borderWidth: '0.5',
          // position: ['20%', '20%'],
          textStyle: {
            color: '#333333'
          },
          // formatter:'{b0}: {c0}<br />{b1}: {c1}',
        },
        xAxis: {
          type: 'category',
          data: this.echarttsData.dataX
        },
        yAxis: {
          type: 'value',
          name: this.echarttsData.unit,
             position: 'left',
            alignTicks: false,
            // offset: 30,
            axisLine: {
              show: false,
              lineStyle: {
                // color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
        },
        legend: {
          left: '35%',
          top: '0',
          itemGap: 50,
          data: [this.echarttsData.title]
        },
        series: [
          {
            name: this.echarttsData.title,
            data:  this.echarttsData.dataY,
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option, true); option && myChart.setOption(option);
              window.addEventListener('resize', function () {
           myChart.resize();
       });
    },

  }
};
</script>

<style scoped lang="scss">
.echartsHistogram {
  height: 370px;
  // width: 1000px;
  margin: auto;
  margin-bottom: 20px;
}

/deep/.el-dialog__title {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
</style>
